import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField/index';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { get, handleResponse, swal500 } from '../../utils/network';
import CustomAutocomplete from './CustomAutocomplete';

/**
 *  Component that autocompletes values loading lazily from a URL
 * 
 * @param disabled      {Boolean}             - If the autocomplete is disabled or not
 * @param error         {String}              - Error to display
 * @param filterLabel   {function(option: T) => String} - Function to call to obtain the string used when filtering options. Is Optional but needed when you want to filter by a different field than the label.
 * @param handleChange  {function (newValue)} - Function to call when the value changes
 * @param label         {String}              - Field of the option/value to display
 * @param name          {String}              - Name of the value to change when handleChange is called
 * @param noOptionsText {String}              - Text to display when there are no options
 * @param path          {String}              - URL to get the options. It has to be missing only 
 *                                              the value of the query param to filter, the page and the size.
 * @example 'roles?role=like:'. In this case the path is roles, and it's filtering by role using like
 * @param title         {String}              - Title to use for the TextField
 * @param value         {Object}              - Initial selected value
 */
class ApiAutocomplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            loading: false
        }

        this.timer = null;
    }

    componentDidMount() {
        this.props.value && this.setState({
            options: [this.props.value]
        });
    }

    fillAutocomplete = newValue => {
        const regex = new RegExp(' ');
        const nameParam = newValue.replace(regex, '%20');
        const url = `${this.props.path}${nameParam}&page=0`;
        get(url)
            .then(res => handleResponse(res, this.props))
            .then(parsed => {
                this.setState(prevState => ({
                    options: parsed.message[this.props.dataField],
                    loading: false
                }))
            }
            )
            .catch(err => {
                swal500(err);
                this.setState({ loading: false });
            });
    };

    handleInputChange = (event, value) => {
        clearTimeout(this.timer);
        if (value) {
            this.timer = setTimeout(() => {
                this.setState({
                    loading: true
                },
                    () => this.fillAutocomplete(value))
            }, 1000);
        }
    };

    handleSelect = (value) => {
        this.props.handleChange({ target: { name: this.props.name, value } });
    };

    render() {
        const { label } = this.props
        return (
            <CustomAutocomplete
                autoHighlight
                {...(this.props.filterLabel && {
                    filterOptions: createFilterOptions({
                        stringify: this.props.filterLabel,
                    })
                })}
                getOptionLabel={(o) => typeof label == "function" ? label(o) : o?.[this.props.label]}
                getOptionSelected={(option, value) => option._id === value._id}
                id="controled-autocomplete"
                loading={this.state.loading}
                loadingText="Cargando..."
                noOptionsText={this.props.noOptionsText}
                onChange={(event, newValue) => this.handleSelect(newValue)}
                onInputChange={this.handleInputChange}
                options={this.state.options}
                value={this.props.value}
                readOnly={this.props.disabled}
                label={this.props.title}
                error={this.props.error}
            />
        )
    }
}

export default ApiAutocomplete;