import React from "react";
import {
    IconButton,
    withStyles,
    TextField,
    MenuItem
} from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./MembershipsFormContainer";
import { ApiAutocomplete, FormLayout } from "../../common";
import Durations from "../durations";
import { NoEditComponent } from "../../../utils/utils";

const MembershipsFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        submitting,
        loading,
        values,
        editMode,
        setEditMode,
        isNewMembership,
        isEditable
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {isEditable ?
                <div>
                    {!editMode && (
                        <IconButton onClick={() => setEditMode(true)}>
                            <CreateIcon />
                        </IconButton>
                    )}
                    {!isNewMembership &&
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    }
                </div>
                : <p>No es posible editarlo</p>}
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewMembership}
            dataKey="Abono"
        >
            <ApiAutocomplete
                error={errors.type}
                handleChange={handleChange}
                label='name'
                name='type'
                path='membershiptypes?name=like:'
                title={FIELDS.type}
                value={values.type}
                noOptionsText="No hay Tipos de Abono con ese nombre"
                disabled={!editMode || submitting}
                dataField="membershipTypes"
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='description'
                error={errors.description !== undefined}
                helperText={errors.description}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                label={FIELDS.description}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='price'
                error={errors.price !== undefined}
                helperText={errors.price}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.price}
                label={FIELDS['price']}
                type="number"
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='lifelongPrice'
                error={errors.lifelongPrice !== undefined}
                helperText={errors.lifelongPrice}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lifelongPrice}
                label={FIELDS['lifelongPrice']}
                type="number"
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='ageFrom'
                error={errors.ageFrom !== undefined}
                helperText={errors.ageFrom}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ageFrom}
                type="number"
                label={FIELDS['ageFrom']}
                disabled={!editMode || submitting}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting || !isNewMembership,
                    disableUnderline: !editMode || !isNewMembership
                }}
            />
            <NoEditComponent isNew={isNewMembership} isInEdit={editMode} />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='ageTo'
                error={errors.ageTo !== undefined}
                helperText={errors.ageTo}
                onChange={handleChange}
                type="number"
                onBlur={handleBlur}
                value={values.ageTo}
                label={FIELDS['ageTo']}
                disabled={!editMode || submitting}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting || !isNewMembership,
                    disableUnderline: !editMode || !isNewMembership
                }}
            />
            <NoEditComponent isNew={isNewMembership} isInEdit={editMode} />
            {editMode && <div className={classes.divider} />}
            <TextField
                select
                name='duration'
                error={errors.duration !== undefined}
                helperText={errors.duration}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.duration}
                label={FIELDS.duration}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting || !isNewMembership,
                    disableUnderline: !editMode || !isNewMembership
                }}
                {...(!isNewMembership && {
                    SelectProps: {
                        IconComponent: () => null
                    }
                })}
            >
                {Object.entries(Durations).map(([duration, keyCode]) => (
                    <MenuItem key={keyCode} value={keyCode}>
                        {duration}
                    </MenuItem>
                ))}
            </TextField>
            <NoEditComponent isNew={isNewMembership} isInEdit={editMode} />
            {editMode && <div className={classes.divider} />}


        </FormLayout>
    );
};

export default withStyles(styles)(MembershipsFormComponent);